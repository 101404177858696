body {
  background-color: #fafafa;
  height: 100%;
  margin: 0;
  padding: 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

#root {
  height: 100%;
}

#head {
  position: sticky;
  top: 0;
}

main {
  margin: 0;
  padding: 0;
  margin-left: 5vw;
  background-color: #fafafa;
  position: relative;
  width: 100%;
  /* width: 100vw;
  height: 100vh; */
  overflow-x: hidden;
}

select {
  /* text-align-last: right; */
  background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png)
    no-repeat right;
  background-color: #f4f4f4;
  -webkit-appearance: none;
  border: none;
  padding: 5px 20px;
  text-transform: uppercase;
}

label {
  color: rgba(0, 0, 0, 0.54);
}

.matcher {
  display: flex;
  width: 100%;
  /* height: 100%; */
  /* overflow: scroll; */
  /* grid-template-columns: 50fr 50fr; */
}

.left {
  float: left;
  width: 50%;
}

.right {
  width: 49%;
  float: right;
  position: fixed;
  top: 297px;
  left: 53vw;
  margin-left: 5px;
  bottom: 0;
  overflow-y: scroll;
}

:root {
  --amplify-primary-color: rgb(199, 190, 184);
  --amplify-primary-tint: #777777;
  --amplify-primary-shade: #777777;
  --amplify-font-family: 'Crimson Text';
}

amplify-greetings {
  --background-color: 'none';
  --border-color: #fff;
}
.modal-list li:nth-child(2n) {
  background: rgb(199, 190, 184);
}
.modal-list li {
  border-bottom: 1px solid;
}
.modal-list li:last-child {
  border-bottom: none;
}
.modal-list nav {
  padding: 0;
}